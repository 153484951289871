import React from 'react';
import styled from 'styled-components';
import Chunk from '../App/Chunk';
import InfoRow from '../App/InfoRow';
import { useSelector } from 'react-redux';
import { getTranslations } from '../../store/localization/selectors';

const Highlight = styled.div`
  text-trasform: uppercase;
  color: #7B3572;
  font-size: 14px;
  font-weight: 700;
`;

const HospitalityFacilities = ({ hospitalityFacilities, lang }) => {
    const translations = useSelector(getTranslations);

    return (
        <Chunk title={translations["sheet-company"]["Hospitality facilities"]}>
            {hospitalityFacilities.map((facility, index) => (
            <Chunk title={facility.name} key={`hf_${index}`}>
                    {facility.countryId && facility.countryId[lang] && (
                        <InfoRow
                            label={translations["sheet-company"]["Place"]}
                            value={`${facility.countryId[lang]}, ${facility.regionId[lang]}, ${facility.provinceId[lang]}`}
                        />
                    )}
                    {facility.categoryIds && facility.categoryIds.length > 0 && (
                        <InfoRow
                            label={translations["sheet-company"]["Categories"]}
                            value={facility.categoryIds.map(category => category[lang]).join(", ")}
                        />
                    )}
                    {facility.referent && facility.referent.firstName && (
                        <InfoRow
                            label={translations["sheet-company"]["Place"]}
                            value={`${facility.referent.firstName} ${facility.referent.lastName}`}
                        />
                    )}
                    {facility.hasHospitalityManager !== undefined && (
                        <InfoRow
                            label={translations["sheet-company"]["HospitalityManager"]}
                            value={facility.hasHospitalityManager ? translations["sheet-company"]["YES"] : translations["sheet-company"]["NO"]}
                        />
                    )}
                    {facility.whoHandleTheVisit && (
                        <InfoRow
                            label={translations["sheet-company"]["Who handles the visit"]}
                            value={facility.whoHandleTheVisit !== 'OTHER' ? translations["sheet-company"][facility.whoHandleTheVisit] : facility.other}
                        />
                    )}
                    {facility.openingDays && facility.openingDays.length > 0 && (
                        <InfoRow
                            label={translations["sheet-company"]["Opening days"]}
                            value={facility.openingDays.map(day => translations["sheet-company"][day]).join(", ")}
                        />
                    )}
                    {facility.openingHours && (
                        <InfoRow
                            label={translations["sheet-company"]["Opening hours"]}
                            value={facility.openingHours}
                        />
                    )}
                    {facility.offeredExperience && (
                        <InfoRow
                            label={translations["sheet-company"]["Offered experience"]}
                            value={translations["sheet-company"][facility.offeredExperience] ?? facility.offeredExperience}
                        />
                    )}
                    {facility.offeredExperienceType && facility.offeredExperienceType.length > 0 && (
                        <InfoRow
                            label={translations["sheet-company"]["Offered experience type"]}
                            value={facility.offeredExperienceType.map(offeredType => offeredType[lang]).join(", ")}
                        />
                    )}
                    {facility.isLodgingAvailable !== undefined && (
                        <InfoRow
                            label={translations["sheet-company"]["Is lodging available"]}
                            value={facility.isLodgingAvailable ? translations["sheet-company"]["YES"] : translations["sheet-company"]["NO"]}
                        />
                    )}
                    {facility.availableRooms && (
                        <InfoRow
                            label={translations["sheet-company"]["Available rooms"]}
                            value={translations["sheet-company"][facility.availableRooms]}
                        />
                    )}
                    {facility.bookingOptions && facility.bookingOptions.length > 0 && (
                        <InfoRow
                            label={translations["sheet-company"]["Booking options"]}
                            value={facility.bookingOptions.map(bookingOption => bookingOption[lang]).join(", ")}
                        />
                    )}
                    {facility.otherBooking && (
                        <InfoRow
                            label={translations["sheet-company"]["Other booking"]}
                            value={facility.otherBooking}
                        />
                    )}
                    {facility.toursPriceRange && facility.toursPriceRange.length > 0 && (
                        <InfoRow
                            label={translations["sheet-company"]["Tours price range"]}
                            value={facility.toursPriceRange.map(priceRange => translations["sheet-company"][priceRange]).join(", ")}
                        />
                    )}
                    {facility.toursLanguageAvailable && facility.toursLanguageAvailable.length > 0 && (
                        <InfoRow
                            label={translations["sheet-company"]["Tours language available"]}
                            value={facility.toursLanguageAvailable.map(language => translations["sheet-company"][language]).join(", ")}
                        />
                    )}
                    {facility.alreadyWorkWithTourOperators !== undefined && (
                        <InfoRow
                            label={translations["sheet-company"]["Already work with tour operators"]}
                            value={facility.alreadyWorkWithTourOperators ? translations["sheet-company"]["YES"] : translations["sheet-company"]["NO"]}
                        />
                    )}
                    {facility.internationalWineShipping !== undefined && (
                        <InfoRow
                            label={translations["sheet-company"]["International wine shipping"]}
                            value={facility.internationalWineShipping ? translations["sheet-company"]["YES"] : translations["sheet-company"]["NO"]}
                        />
                    )}
                </Chunk>
            ))}
        </Chunk>
    );
};

export default HospitalityFacilities;